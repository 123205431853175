<template lang="html">
  <div class="pdf">
    <div class="pdf-inner">
      <pdf
        :src="file"
        :page="i"
        @num-pages="pageCount = $event"
        @page-loaded="currentPage = $event"
      ></pdf>
    </div>
    <div class="controls">
      <div v-if="i > 1" @click="i--">Previous</div>
      <div class="px">{{ currentPage }} / {{ pageCount }}</div>
      <div v-if="i < pageCount" @click="i++">Next</div>
    </div>
  </div>
</template>

<script>
import pdf from "vue-pdf";
export default {
  props: {
    file: String,
  },
  data() {
    return {
      currentPage: 0,
      pageCount: 0,
      i: 1,
    };
  },
  components: {
    pdf,
  },
};
</script>

<style lang="scss">
.pdf {
  .pdf-inner {
    padding: 0 calc(2 * var(--lh));
    height: calc(100% - (2 * var(--lh)));
    overflow: scroll;
  }
  .controls {
    margin-top: var(--lh);
    display: flex;
    justify-content: center;
    > div {
      cursor: pointer;
    }
    .px {
      padding: 0 var(--lh);
    }
  }
}
</style>
